import styled from 'styled-components';

interface StoreSectionStyledProps {
  backgroundColor: string
}

export const Section = styled.section<StoreSectionStyledProps>`
  background-color: ${({ backgroundColor }) => (backgroundColor)};

  @media screen and (min-width: 768px) {
    height: 319px;
  }

  @media screen and (min-width: 1200px) {
    height: 510px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 0 64px;
  box-sizing: border-box;

  @media screen and (min-width: 768px) {
    padding: 64px 0;
    width: 50%;
  }

  @media screen and (min-width: 1200px) {
    padding: 144px 0;
  }
`;

export const StoreContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 26px;

  a:first-child {
    margin-bottom: 24px;
  }

  @media screen and (min-width: 375px) {
    flex-direction: row;
  }

  @media screen and (min-width: 1024px) {
    margin-top: 64px;
  }
`;
