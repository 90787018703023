import React from 'react';

import Accordion from '../Accordion/old';

import * as S from './styled';

const FAQ = () => (
  <S.FAQSection>
    <S.FAQContainer className="container">
      <S.FAQTitle>Perguntas frequentes</S.FAQTitle>

      <Accordion />
    </S.FAQContainer>
  </S.FAQSection>
);

export default FAQ;
