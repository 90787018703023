import React, { ReactNode } from 'react'

import Header from '../../Header'
import Footer from '../../Footer/old'

import { GlobalStyles } from './GlobalStyles'

interface LayoutProps {
  pageType: string
  children: ReactNode
  toggle: () => void
}

const Layout = ({ pageType, children, toggle }: LayoutProps): JSX.Element => {

  const storeLink = pageType === 'home'
    ? 'https://app.adjust.com/8b5j6bz?campaign=home_site&engagement_type=fallback_click'
    : 'https://app.adjust.com/u9i22ex?campaign=lp_boletos_botoes_gerais'

  return (
    <>
      <GlobalStyles />
      <Header toggle={toggle} />
      <main>{children}</main>
      <Footer storeLink={storeLink} />
    </>
  )
}

export default Layout
