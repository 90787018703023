import React from 'react';
import Plx from 'react-plx';
import { isMobileOnly } from 'react-device-detect';

import * as S from './_styled';

import ImageSmall from '../../images/phone4-mobile.png';
import ImageSmall2X from '../../images/phone4-mobile@2x.png';
import ImageLarge from '../../images/phone4-desktop.png';
import ImageLarge2X from '../../images/phone4-desktop@2x.png';

const parallaxImage = [
  {
    start: 'self',
    duration: 1000,
    properties: [
      {
        startValue: 0,
        endValue: 150,
        property: 'translateY',
      },
    ],
  },
];

const ImageEmissaoBoletos = () => (
  <S.ImageWrapper>
    <Plx parallaxData={parallaxImage} tagName="picture" disabled={isMobileOnly}>
      <source media="(min-width: 768px)" srcSet={`${ImageLarge}, ${ImageLarge2X} 2x`} />
      <img srcSet={`${ImageSmall}, ${ImageSmall2X} 2x`} alt="imagem de um celular com o aplicativo da Cora" />
    </Plx>
  </S.ImageWrapper>
);

export default ImageEmissaoBoletos;
