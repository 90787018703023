import React from 'react';
import Plx from 'react-plx';

import * as S from './_styled';

import ImageSmall from '../../images/boletos-456w.png';
import ImageMedium from '../../images/boletos-912w.png';
import ImageLarge from '../../images/boletos-849w.png';
import ImageExtraLarge from '../../images/boletos-1698w.png';

const parallaxImage = [
  {
    start: 0,
    duration: 1000,
    properties: [
      {
        startValue: 0,
        endValue: 150,
        property: 'translateY',
      },
    ],
  },
];

const ImageBoletos = () => (
  <Plx parallaxData={parallaxImage}>
    <S.ImageWrapper>
      <source media="(min-width: 768px)" srcSet={`${ImageLarge}, ${ImageExtraLarge} 2x`} />
      <S.Image srcSet={`${ImageSmall}, ${ImageMedium} 2x`} alt="imagem com boletos voando" />
    </S.ImageWrapper>
  </Plx>
);

export default ImageBoletos;
