import React from 'react'
import { Check } from '@corabank/icons'

const issueSlipData = [
  {
    icon: <Check color="#3B3B3B" />,
    text: 'Faça a emissão de boletos em poucos minutos pelo app da Cora.',
  },
  {
    icon: <Check color="#3B3B3B" />,
    text: 'Após a confirmação de pagamento do seu boleto, o dinheiro é liberado na conta em até 3 dias úteis.',
  },
  {
    icon: <Check color="#3B3B3B" />,
    text: 'Acompanhe os recebimentos de todos os boletos gerados direto no aplicativo.',
  },
]

export default issueSlipData
