import React, { FC, ReactNode} from 'react';

import * as S from './styled';

interface HeadingProps {
  center?: boolean
  dark?: boolean
  headingLevel: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  children: ReactNode
}

const Heading: FC<HeadingProps> = ({
  center = false, dark = false, headingLevel, children,
}: HeadingProps) => {
  const HeadingTag = headingLevel;

  return (
    <S.Heading center={center} dark={dark}>
      <HeadingTag>{children}</HeadingTag>
    </S.Heading>
  );
};

export default Heading;
