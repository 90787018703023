import React, { FC } from 'react';

import * as S from './styled';

import ImageSmall from '../../../images/app-store-152w.png';
import ImageSmall2X from '../../../images/app-store-304w.png';
import ImageMedium from '../../../images/app-store-172w.png';
import ImageMedium2X from '../../../images/app-store-344w.png';
import ImageLarge from '../../../images/app-store-227w.png';
import ImageLarge2X from '../../../images/app-store-454w.png';

interface IconGooglePlayProps {
  className?: string
  link?: string
  small?: boolean
}

const IconGooglePlay: FC<IconGooglePlayProps> = ({
  className,
  link = 'https://apps.apple.com/br/app/cora-empresas/id1494750820?l=pt&ls=1',
  small = false
}: IconGooglePlayProps) => (
  <S.AppStore
    href={link}
    className={className}
    target="_blank"
    rel="noopener noreferrer"
  >
    {small ? (
      <picture>
        <source media="(max-width: 400px)" srcSet={`${ImageSmall}, ${ImageSmall2X} 2x`} />
        <img srcSet={`${ImageMedium}, ${ImageMedium2X} 2x`} alt="Baixar na App Store" />
      </picture>
    ) : (
      <picture>
        <source media="(max-width: 400px)" srcSet={`${ImageSmall}, ${ImageSmall2X} 2x`} />
        <source media="(max-width: 1112px)" srcSet={`${ImageMedium}, ${ImageMedium2X} 2x`} />
        <img srcSet={`${ImageLarge}, ${ImageLarge2X} 2x`} alt="Baixar na App Store" />
      </picture>
    )}
  </S.AppStore>
);

export default IconGooglePlay;
