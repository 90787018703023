import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styled';

const Section = ({
  dark, backgroundImageLarge, backgroundImageSmall, height, overflow, id, children,
}) => (
  <S.Section
    dark={dark}
    backgroundImageLarge={backgroundImageLarge}
    backgroundImageSmall={backgroundImageSmall}
    height={height}
    overflow={overflow}
    id={id}
  >
    <S.SectionContainer className="container">
      {children}
    </S.SectionContainer>
  </S.Section>
);

Section.propTypes = {
  dark: PropTypes.bool,
  backgroundImageLarge: PropTypes.string,
  backgroundImageSmall: PropTypes.string,
  height: PropTypes.string,
  overflow: PropTypes.bool,
  id: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Section.defaultProps = {
  dark: false,
  backgroundImageLarge: '',
  backgroundImageSmall: '',
  height: '',
  overflow: false,
  id: '',
};

export default Section;
