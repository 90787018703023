import styled from 'styled-components';

export const Section = styled.section`
  background-color: ${({ dark }) => (dark ? '#193044' : '#FFF')};
  background-image: ${({ backgroundImageSmall }) => (backgroundImageSmall ? `url(${backgroundImageSmall})` : 'none')};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: ${({ height }) => (height ? `${height}px` : 'auto')};
  overflow: ${({ overflow }) => (overflow ? 'hidden' : 'initial')};

  @media screen and (min-width: 768px) {
    background-image: ${({ backgroundImageLarge }) => (backgroundImageLarge ? `url(${backgroundImageLarge})` : 'none')};
    min-height: auto;
  }
`;

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
`;
