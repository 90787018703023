import styled from 'styled-components';

export const FluidContent = styled.div`
  padding: 64px 0;

  @media screen and (min-width: 768px) {
    padding: 144px 0;
  }
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  margin-top: 40px;

  @media screen and (min-width: 1024px) {
    flex-direction: row;
  }
`;

export const ListItem = styled.li`
  display: flex;
  color: #FFF;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 32px;

  @media screen and (min-width: 1024px) {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 0;
    margin-right: 24px;
  }
`;

export const Icon = styled.img`
  margin-right: 18px;
  width: 56px;
  height: 56px;

  @media screen and (min-width: 768px) {
    height: 72px;
    width: 72px;
  }
`;
