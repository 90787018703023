import React, { FC, ReactNode } from 'react';

import * as S from './styled';

interface TitleComponentStyledProps {
  dark?: boolean
  center?: boolean
  classStyle?: string
  children: ReactNode
}

const TitleComplement: FC<TitleComponentStyledProps> = ({
  dark = false, center = false, classStyle, children,
}: TitleComponentStyledProps) => (
  <S.Text className={classStyle} dark={dark} center={center}>{children}</S.Text>
);

export default TitleComplement;
