import React from 'react';
import Plx from 'react-plx';

import * as S from './_styled';

import ImagePhoneSmall from '../../images/phone-mobile.png';
import ImagePhoneSmall2X from '../../images/phone-mobile@2x.png';
import ImagePhoneMedium from '../../images/phone-tablet.png';
import ImagePhoneMedium2X from '../../images/phone-tablet@2x.png';
import ImagePhoneLarge from '../../images/phone-desktop.png';
import ImagePhoneLarge2X from '../../images/phone-desktop@2x.png';
import ImageCardSmall from '../../images/cards-mobile.png';
import ImageCardSmall2X from '../../images/cards-mobile@2x.png';
import ImageCardMedium from '../../images/cards-tablet.png';
import ImageCardMedium2X from '../../images/cards-tablet@2x.png';
import ImageCardLarge from '../../images/cards-desktop.png';
import ImageCardLarge2X from '../../images/cards-desktop@2x.png';

const parallaxPhoneImage = [
  {
    start: '#open-account',
    duration: 600,
    properties: [
      {
        startValue: 100,
        endValue: 0,
        property: 'translateX',
      },
    ],
  },
];

const parallaxCardImage = [
  {
    start: '#open-account',
    duration: 2000,
    properties: [
      {
        startValue: 0,
        endValue: 300,
        property: 'translateX',
      },
    ],
  },
];

const ImageAccount = () => (
  <S.ImageWrapper>
    <Plx parallaxData={parallaxPhoneImage}>
      <S.FirstImage>
        <source media="(min-width: 1200px)" srcSet={`${ImagePhoneLarge}, ${ImagePhoneLarge2X} 2x`} />
        <source media="(min-width: 768px)" srcSet={`${ImagePhoneMedium}, ${ImagePhoneMedium2X} 2x`} />
        <img srcSet={`${ImagePhoneSmall}, ${ImagePhoneSmall2X} 2x`} alt="imagem de celular com o aplicativo da Cora" />
      </S.FirstImage>
    </Plx>
    <Plx parallaxData={parallaxCardImage}>
      <S.SecondImage>
        <source media="(min-width: 1200px)" srcSet={`${ImageCardLarge}, ${ImageCardLarge2X} 2x`} />
        <source media="(min-width: 768px)" srcSet={`${ImageCardMedium}, ${ImageCardMedium2X} 2x`} />
        <img srcSet={`${ImageCardSmall}, ${ImageCardSmall2X} 2x`} alt="imagem do cartão Cora" />
      </S.SecondImage>
    </Plx>
  </S.ImageWrapper>
);

export default ImageAccount;
