import React from 'react';
import Plx from 'react-plx';

import * as S from './styled';

import {
  parallaxPhoneOne,
  parallaxPhoneTwo,
  ParallaxStyle,
} from './parallax-settings';

import ImageOneSmall from '../../images/phone2-mobile.png';
import ImageOneSmall2X from '../../images/phone2-mobile@2x.png';
import ImageOneMedium from '../../images/phone2-tablet.png';
import ImageOneMedium2X from '../../images/phone2-tablet@2x.png';
import ImageOneLarge from '../../images/phone2-desktop.png';
import ImageOneLarge2X from '../../images/phone2-desktop@2x.png';
import ImageTwoSmall from '../../images/phone3-mobile.png';
import ImageTwoSmall2X from '../../images/phone3-mobile@2x.png';
import ImageTwoMedium from '../../images/phone3-tablet.png';
import ImageTwoMedium2X from '../../images/phone3-tablet@2x.png';
import ImageTwoLarge from '../../images/phone3-desktop.png';
import ImageTwoLarge2X from '../../images/phone3-desktop@2x.png';

const ImageStore = () => (
  <S.Container>
    <Plx parallaxData={parallaxPhoneOne} style={ParallaxStyle}>
      <S.FirstImage>
        <source
          media="(min-width: 1200px)"
          srcSet={`${ImageOneLarge}, ${ImageOneLarge2X} 2x`}
        />

        <source
          media="(min-width: 768px)"
          srcSet={`${ImageOneMedium}, ${ImageOneMedium2X} 2x`}
        />

        <img
          srcSet={`${ImageOneSmall}, ${ImageOneSmall2X} 2x`}
          alt="celular com o aplicativo da cora na tela inicial"
        />
      </S.FirstImage>
    </Plx>

    <Plx parallaxData={parallaxPhoneTwo} style={ParallaxStyle}>
      <S.SecondImage>
        <source
          media="(min-width: 1200px)"
          srcSet={`${ImageTwoLarge}, ${ImageTwoLarge2X} 2x`}
        />

        <source
          media="(min-width: 768px)"
          srcSet={`${ImageTwoMedium}, ${ImageTwoMedium2X} 2x`}
        />

        <img
          srcSet={`${ImageTwoSmall}, ${ImageTwoSmall2X} 2x`}
          alt="celular com o aplicativo da cora em uma tela de cartão de crédito"
        />
      </S.SecondImage>
    </Plx>
  </S.Container>
);

export default ImageStore;
