import React from 'react';

import Heading from '../../components/Heading/old';
import QuotedTitle from '../../components/QuotedTitle';
import List from '../../components/List';

import * as S from './_styled';

import ImageEmissaoBoletos from './_image';

import data from '../../data/issue-slips'

const IssueSlipBankSection = () => (
  <S.Section>
    <S.Container className="container">
      <ImageEmissaoBoletos />

      <S.ContentWrapper>
        <Heading headingLevel="h2">
          <QuotedTitle>Emissão de boletos</QuotedTitle>
          {' '}
          com a agilidade e simplicidade que o seu negócio precisa.
        </Heading>

        <List data={data} fluid />
      </S.ContentWrapper>
    </S.Container>
  </S.Section>
);

export default IssueSlipBankSection;
