import React, { useState } from 'react'

import Layout from '../components/Layout/old'
import SEO from '../components/seo'
import FAQ from '../components/FAQ'
import StoreSection from '../components/StoreSection'
import Heading from '../components/Heading/old'
import QuotedTitle from '../components/QuotedTitle'
import TitleComplement from '../components/TitleComplement'

import SlipBankSection from '../partials/SlipBank/_index'
import IssueSlipBankSection from '../partials/IssueSlipBank/_index'
import WithReceivePayments from '../partials/WithReceivePayments/_index'
import OpenAccountSection from '../partials/OpenAccount/_index'
import ControlSlipBankSection from '../partials/ControlSlipBank/_index'
import SideSection from '../partials/commons/SideSection'

const BoletosPage = (): JSX.Element => {
  const [toggleForm, setToggleForm] = useState(false)

  const toggle = () => {
    setToggleForm(true)
  };

  return (
    <Layout pageType="boletos" toggle={toggle}>
      <SEO title="Emitir boleto de cobrança - Boleto gratuito e ilimitado | Cora" linkCanonical="boletos" />
      <SlipBankSection toggle={toggle} />
      <IssueSlipBankSection />
      <WithReceivePayments />
      <OpenAccountSection toggle={toggle}>
        <Heading headingLevel="h2">
          Basta criar uma conta
          {' '}
          <QuotedTitle>gratuita</QuotedTitle>
          {' '}
          na Cora
        </Heading>
        <TitleComplement>
          <strong>É super rápido:</strong>
          {' '}
          alguns minutos para criar sua conta e sua empresa poderá emitir boletos registrados sem custo.
        </TitleComplement>
      </OpenAccountSection>
      <ControlSlipBankSection />
      <FAQ />
      <StoreSection storeLink="https://app.adjust.com/u9i22ex?campaign=lp_boletos_botoes_gerais" />
      <SideSection
        toggleForm={toggleForm}
        setToggleForm={setToggleForm}
        idFinishForm="FormFinishedBoletos"
        pageType="boletos"
      />
    </Layout>
  )
}

export default BoletosPage
