import styled from 'styled-components';

export const ImageWrapper = styled.div`
  height: 390px;
  position: relative;
  display: flex;

  @media screen and (min-width: 768px) {
    width: 50%;
    height: 100%;
  }

  @media screen and (min-width: 1024px) {
    height: 100%;
  }
`;

export const FirstImage = styled.picture`
  display: flex;
  height: 339px;
  position: absolute;
  width: 452px;
  z-index: 1;
  left: -60px;
  top: -30px;

  @media screen and (min-width: 768px) {
    top: 0;
    left: -15px;
    width: 527px;
    height: 397px;
  }

  @media screen and (min-width: 1025px) {
    top: -60px;
    left: -30px;
    height: 592px;
    width: 787px;
  }

  img {
    width: 100%;
    height: 100%;
  }
`;

export const SecondImage = styled.picture`
  height: 138px;
  position: absolute;
  width: 420px;
  z-index: 2;
  top: 200px;
  left: -60px;

  @media screen and (min-width: 414px) {
    left: -40px;
  }

  @media screen and (min-width: 768px) {
    top: 260px;
    left: -70px;
    width: 491px;
    height: 160px;
  }

  @media screen and (min-width: 1200px) {
    height: 238px;
    width: 733px;
    top: 330px;
    left: -80px;
  }

  @media screen and (min-width: 1400px) {
    left: -80px;
  }

  img {
    width: 100%;
    height: 100%;
  }
`;

export const ContentWrapper = styled.div`
  padding-top: 48px;
  padding-bottom: 28px;

  @media screen and (min-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 80px 0 128px;
    width: 373px;
  }

  @media screen and (min-width: 1024px) {
    padding: 120px 0 177px;
    width: 466px;
  }
`;
