import styled from 'styled-components';

export const AccordionWrapper = styled.div`
  background: #FFFFFF;
  box-shadow: 0px 8px 16px rgba(32, 32, 32, 0.06);
  border-radius: 8px;
  padding: 24px;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const AccordionHeader = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const AccordionContent = styled.div`
  margin-top: ${({ open }) => (open ? '16px' : '0')};
  max-height: ${({ open }) => (open ? 'auto' : '0')};
  opacity: ${({ open }) => (open ? '1' : '0')};
  visibility: ${({ open }) => (open ? 'visible' : 'hidden')};
  transition: opacity .3s ease, max-height .4s ease;
`;

export const AccordionQuestion = styled.h4`
  color: #202020;
  font-size: 14px;
  font-weight: 800;
  line-height: 20px;
  margin-right: 30px;
`;

export const AccordionAnswer = styled.p`
  font-size: 14px;
  line-height: 20px;
  color: #888E93;
`;

export const AccordionIcon = styled.img`
  height: 15px;
  margin-left: auto;
  width: 15px;
`;
