import { createGlobalStyle } from 'styled-components'
import reset from 'styled-reset'
import { device } from '../../../styles/breakpoints'

export const GlobalStyles = createGlobalStyle`
  ${reset}

  *, *::after, *::before {
    box-sizing: border-box;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
  }

  html {
    font-size: 10px;
    scroll-behavior: smooth;
  }

  body {
    font-family: 'Nunito', sans-serif;
    font-weight: 400;
    font-size: 1.6rem;
    height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100vw;
    -webkit-overflow-scrolling: touch;
  }

  strong {
    font-weight: 800;
  }

  .container {
    display: flex;
    margin: 0 auto;
    max-width: 1200px;
    width: 100%;
    padding: 0 24px;

    @media ${device.tabletL} {
      padding: 0 32px;
    }

    @media ${device.laptopM} {
      padding: 0 64px;
    }
  }
`
