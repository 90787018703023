import styled from 'styled-components';

export const GooglePlay = styled.a`
  display: block;
  margin-right: 16px;

  @media screen and (min-width: 1024px) {
    margin-right: 24px;
  }
`;
