import styled from 'styled-components';

export const Section = styled.section`
  overflow-y: hidden;

  @media screen and (min-width: 768px) {
    height: 660px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
`;

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  order: 2;

  @media screen and (min-width: 768px) {
    order: 1;
    width: 50%;
    height: 100%;
  }

  picture {
    width: 199px;
    height: 386px;

    @media screen and (min-width: 768px) {
      width: 378px;
      height: 733px;
    }
  }

  img {
    width: 100%;
    height: 100%;
  }
`;

export const ContentWrapper = styled.div`
  order: 1;
  padding-bottom: 28px;
  padding-top: 48px;

  @media screen and (min-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: auto;
    padding-bottom: 48px;
    min-width: 400px;
  }

  @media screen and (min-width: 1024px) {
    min-width: 556px;
    padding: 120px 0;
    width: 556px;
  }
`;
