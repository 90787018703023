import React from 'react';
import PropTypes from 'prop-types';

import Section from '../../components/Section';
import Button from '../../components/Button/old';

import * as S from './_styled';

import ImageAccount from './_image';

const OpenAccountSection = ({ toggle, children }) => (
  <Section overflow id="open-account">
    <S.ContentWrapper>
      { children }

      <Button handleClick={toggle}>Abra sua Conta</Button>
    </S.ContentWrapper>

    <ImageAccount />
  </Section>
);

OpenAccountSection.propTypes = {
  toggle: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default OpenAccountSection;
