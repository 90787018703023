/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const ContentWrapper = styled.div`
  padding-bottom: 28px;
  padding-top: 48px;
  padding-bottom: 400px;

  @media screen and (min-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: auto;
    padding-bottom: 48px;
    min-width: 400px;
    width: 400px;
  }

  @media screen and (min-width: 1024px) {
    padding: 120px 0;
    width: 556px;
  }
`;
