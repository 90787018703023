import styled from 'styled-components';

interface HeadingStyledProps {
  dark: boolean
  center: boolean
}

export const Heading = styled.div<HeadingStyledProps>`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 800;
    color: ${({ dark }) => (dark ? '#FFF' : '#202020')};
    font-size: 28px;
    line-height: 38px;
    text-align: ${({ center }) => (center ? 'center' : 'initial')};

    @media screen and (min-width: 768px) {
      font-size: 28px;
      line-height: 38px;
    }

    @media screen and (min-width: 1200px) {
      font-size: 36px;
      line-height: 48px;
    }
  }
`;
