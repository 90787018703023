import styled from 'styled-components';

export const FooterWrapper = styled.footer`
  background-color: #193044;
  color: #FFF;
`;

export const FooterContent = styled.div`
  padding-bottom: 64px;
  padding-top: 64px;
  flex-direction: column;
  width: 100%;

  @media screen and (min-widtH: 1024px) {
    padding-bottom: 120px;
    padding-top: 120px;
  }
`;

export const FooterRow = styled.div`
  &:first-child {
    margin-bottom: 56px;
  }

  @media screen and (min-widtH: 1024px) {
    display: flex;

    &:last-child {
      margin-top: 72px;
    }
  }
`;

export const ListWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;

  :not(:last-child) {
    margin-bottom: 56px;
  }

  &.store-list {
    .store-icon {
      &:not(:last-child) {
        margin-right: 16px;
      }
    }
  }

  @media screen and (min-width: 1024px) {
    align-items: flex-start;
    width: calc(100%/4);

    &.store-list {
      width: auto;
    }

    :not(:last-child) {
      margin-bottom: 0;
    }
  }
`;

export const ListTitle = styled.h5`
  font-weight: 800;
  font-size: 22px;
  line-height: 32px;
  margin-bottom: 16px;
`;

export const List = styled.ul`
  text-align: center;
  font-size: 16px;
  line-height: 32px;

  @media screen and (min-widtH: 1024px) {
    text-align: left;
  }
`;

export const Link = styled.a`
  color: #FFF;
  cursor: pointer;
  text-decoration: none;
  transition: color .3s ease;

  &:hover {
    color: #FE3E6D;
  }
`;

export const FooterCopyright = styled.div`
  background-color: #142839;
`;

export const FooterCopyrightContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media screen and (min-width: 1024px) {
    flex-direction: row;
  }
`;

export const LinkStoresContainer = styled.div`
  display: flex;
`;
