import React from 'react';

import Section from '../../components/Section';
import Heading from '../../components/Heading/old';
import QuotedTitle from '../../components/QuotedTitle';
import List from '../../components/List';

import * as S from './_styled';

import slipManagement from '../../data/slip-management';

import ManagementImageBackgroundSmall from '../../images/background-management-small.jpg';
import ManagementImageBackgroundLarge from '../../images/background-management-large.jpg';

const ControlSlipBankSection = () => (
  <Section
    backgroundImageLarge={ManagementImageBackgroundLarge}
    backgroundImageSmall={ManagementImageBackgroundSmall}
  >
    <S.ContentWrapper align="right" order="1">
      <Heading dark headingLevel="h2">
        Gestão de boletos
        {' '}
        <QuotedTitle>centralizada</QuotedTitle>
        .
      </Heading>

      <List dark data={slipManagement} fluid />
    </S.ContentWrapper>
  </Section>
);

export default ControlSlipBankSection;
