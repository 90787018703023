import React from 'react';
import PropTypes from 'prop-types';

import Section from '../../components/Section';
import Heading from '../../components/Heading/old';
import QuotedTitle from '../../components/QuotedTitle';
import TitleComplement from '../../components/TitleComplement';
import Button from '../../components/Button/old';

import ImageBoletos from './_image';

import * as S from './_styled';

const SlipBankSection = ({ toggle }) => (
  <Section dark overflow>
    <S.ContentWrapper>
      <Heading dark headingLevel="h1">
        Use a Conta Digital Cora para emitir boletos de cobrança sem burocracia.
      </Heading>

      <TitleComplement dark classStyle="s-text-seo">
        Fique livre das taxas abusivas cobradas por bancos na hora de gerar boletos registrados.
      </TitleComplement>

      <Button handleClick={toggle}>Abra sua Conta</Button>
    </S.ContentWrapper>

    <ImageBoletos />
  </Section>
);

SlipBankSection.propTypes = {
  toggle: PropTypes.func.isRequired,
};

export default SlipBankSection;
