import React from 'react'
import { Check } from '@corabank/icons'

const slipManagement = [
  {
    icon: <Check color="#FFF" />,
    text: 'Acompanhe pelo app os boletos que estão abertos, os atrasados e os que já foram recebidos.',
  },
  {
    icon: <Check color="#FFF" />,
    text: 'Defina o melhor dia de vencimento para as cobranças.',
  },
  {
    icon: <Check color="#FFF" />,
    text: 'Escolha se aplica multas e juros aos boletos emitidos.',
  },
]

export default slipManagement
