import React, { FC, ReactNode } from 'react';

import * as S from './styled';

interface ButtonProps {
  type?: 'button' | 'submit' | 'reset'
  fluid?: boolean
  handleClick: Function
  id?: string
  children: ReactNode
}

const Button: FC<ButtonProps> = ({
  type = 'submit',
  fluid = false,
  handleClick,
  children,
  id,
}: ButtonProps) => (
  <S.Button
    type={type}
    fluid={fluid}
    onClick={() => handleClick()}
    id={id}
  >
    {children}
  </S.Button>
);

export default Button;
