import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

const Text = styled.span`
  color: #FE3E6D;
`;

interface QuotedTitleProps {
  children: ReactNode
}

const QuotedTitle: FC<QuotedTitleProps> = ({ children }: QuotedTitleProps) => (
  <Text>{children}</Text>
);

export default QuotedTitle;
