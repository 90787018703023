import React, { FC } from 'react'

import * as S from './styled'

import Instagram from '../../../images/instagram.svg'
import LinkedIn from '../../../images/linkedin.svg'
import Facebook from '../../../images/facebook.svg'

const Social: FC = () => (
  <S.SocialWrapper>
    <S.SocialItem>
      <S.Link
        href="https://www.instagram.com/cora.bancopj/"
        title="Instagram da Cora"
      >
        <img src={Instagram} alt="Instagram da Cora" />
      </S.Link>
    </S.SocialItem>
    <S.SocialItem>
      <S.Link
        href="https://www.linkedin.com/company/cora-bancopj/"
        title="LinkedIn da Cora"
      >
        <img src={LinkedIn} alt="LinkedIn da Cora" />
      </S.Link>
    </S.SocialItem>
    <S.SocialItem>
      <S.Link
        href="https://www.facebook.com/cora.bancopj/"
        title="Facebook da Cora"
      >
        <img src={Facebook} alt="Facebook da Cora" />
      </S.Link>
    </S.SocialItem>
  </S.SocialWrapper>
)

export default Social
