export const parallaxPhoneOne = [
  {
    start: '#store-section',
    duration: 300,
    properties: [
      {
        startValue: -80,
        endValue: -50,
        property: 'translateX',
      },
    ],
  },
  {
    start: '#store-section',
    duration: 300,
    properties: [
      {
        startValue: 0,
        endValue: 1,
        property: 'opacity',
      },
    ],
  },
  {
    start: '#store-section',
    startOffset: 600,
    duration: 60,
    properties: [
      {
        startValue: 0,
        endValue: -50,
        property: 'translateY',
      },
    ],
  },
];

export const parallaxPhoneTwo = [
  {
    start: '#store-section',
    duration: 300,
    properties: [
      {
        startValue: 80,
        endValue: 50,
        property: 'translateX',
      },
    ],
  },
  {
    start: '#store-section',
    duration: 300,
    properties: [
      {
        startValue: 0,
        endValue: 1,
        property: 'opacity',
      },
    ],
  },
  {
    start: '#store-section',
    startOffset: 600,
    duration: 300,
    properties: [
      {
        startValue: 0,
        endValue: 50,
        property: 'translateY',
      },
    ],
  },
];

export const ParallaxStyle = {
  opacity: 0,
  position: 'absolute',
  top: 0,
};
