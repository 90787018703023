import styled from 'styled-components';

export const FAQSection = styled.section`
  background-color: #F0F4F8;
`;

export const FAQContainer = styled.section`
  padding-bottom: 128px;
  padding-top: 64px;
  flex-direction: column;
`;

export const FAQTitle = styled.h2`
  font-weight: 800;
  font-size: 28px;
  line-height: 38px;
  color: #202020;
  margin-bottom: 48px;

  @media screen and (min-width: 768px) {
    text-align: center;
  }
`;
