import React, { FC } from 'react';

import QuotedTitle from '../QuotedTitle';

import Heading from '../Heading/old';
import ImageStore from '../ImageStore';
import IconGooglePlay from '../IconGooglePlay/old';
import IconAppStore from '../IconAppStore/old';

import * as S from './styled';

interface SideSectionProps {
  storeLink?: string
  backgroundColor?: string
}

const StoreSection: FC<SideSectionProps> = ({ storeLink, backgroundColor = '#FFF'}: SideSectionProps) => (
  <S.Section backgroundColor={backgroundColor} id="store-section">
    <S.Container className="container">
      <ImageStore />

      <S.ContentWrapper>
        <Heading headingLevel="h2">
          <QuotedTitle>Tudo que você precisa</QuotedTitle>
          {' '}
          na palma da sua mão.
        </Heading>

        <S.StoreContainer>
          <IconGooglePlay link={storeLink} />

          <IconAppStore link={storeLink} />
        </S.StoreContainer>
      </S.ContentWrapper>
    </S.Container>
  </S.Section>
);

export default StoreSection;
