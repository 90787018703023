import styled from 'styled-components';

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 40px 0 20px;
`;

export const ListItem = styled.li`
  align-items: center;
  color: ${({ dark }) => (dark ? '#FFF' : '#202020')};
  display: flex;
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;

  &:not(:last-child) {
    margin-bottom: 24px;
  }
`;

export const IconList = styled.div`
  align-items: center;
  background: ${({ dark }) => (dark ? '#2B4153' : '#EAF7FF')};
  border-radius: 50%;
  display: flex;
  height: 40px;
  justify-content: center;
  margin-right: 16px;
  width: 40px;
`;

export const TextList = styled.span`
  max-width: 80%;

  small {
    font-size: 14px;
    color: #FE3E6D;
  }
`;
