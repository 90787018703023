import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
  position: relative;

  @media screen and (min-width: 768px) {
    order: 2;
    width: 50%;
    height: 100%;
  }
`;

export const FirstImage = styled.picture`
  margin-top: -60px;
  width: 101px;
  height: 309px;
  display: flex;

  @media screen and (min-width: 768px) {
    width: 128px;
    height: 394px;
    margin-left: -70px;
  }

  @media screen and (min-width: 1200px) {
    width: 196px;
    height: 604px;
    margin-left: -150px;
  }

  img {
    width: 100%;
    height: 100%;
  }
`;

export const SecondImage = styled.picture`
  margin-top: -20px;
  margin-left: 24px;
  width: 100px;
  height: 295px;
  display: flex;

  @media screen and (min-width: 768px) {
    width: 127px;
    margin-left: 24px;
    height: 376px;
    margin-top: 5px;
  }

  @media screen and (min-width: 1200px) {
    margin-top: 5px;
    margin-left: 80px;
    width: 194px;
    height: 575px;
  }

  img {
    width: 100%;
    height: 100%;
  }
`;
