import styled from 'styled-components';

interface TitleComponentStyledProps {
  dark: boolean
  center: boolean
}

export const Text = styled.span<TitleComponentStyledProps>`
  color: ${({ dark }) => (dark ? '#FFF' : '#202020')};
  display: block;
  line-height: 24px;
  padding-bottom: 24px;
  padding-top: 16px;

  @media screen and (min-width: 768px) {
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 24px;
    padding-top: 16px;
    text-align: ${({ center }) => (center ? 'center' : 'left')};
  }

  @media screen and (min-width: 1200px) {
    font-size: 22px;
    line-height: 32px;
    padding-bottom: 40px;
    padding-top: 24px;
  }
`;
