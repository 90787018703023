import styled from 'styled-components';

export const ImageWrapper = styled.picture`
  order: ${({ order }) => order};
  position: relative;
`;

export const Image = styled.img`
  margin-left: -60px;
  width: 120vw;

  @media screen and (min-width: 768px) {
    margin-right: -134px;
    width: initial;
    margin-top: -120px;
  }

  @media screen and (min-width: 1024px) {
    margin-left: 0;
  }
`;

export const ContentWrapper = styled.div`
  order: ${({ order }) => order};
  padding-top: 48px;

  @media screen and (min-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: ${({ align }) => (align === 'right' ? 'auto' : '0')};
    padding-bottom: 48px;
    min-width: 400px;
  }

  @media screen and (min-width: 1024px) {
    padding: 120px 0;
    width: 486px;
  }
`;
