import React from 'react';

import Section from '../../components/Section';
import Heading from '../../components/Heading/old';

import * as S from './_styled';

import paymentData from '../../data/payment';

const WithReceivePayments = () => (
  <Section dark>
    <S.FluidContent>
      <Heading dark headingLevel="h2">
        Como você recebe os pagamentos?
      </Heading>


      <S.List>
        {paymentData.map((item) => (
          <S.ListItem key={item.text}>
            <S.Icon src={item.icon} alt="ícone da lista de pagamentos" />
            {item.text}
          </S.ListItem>
        ))}
      </S.List>
    </S.FluidContent>
  </Section>
);

export default WithReceivePayments;
