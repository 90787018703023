import React from 'react';
import PropTypes from 'prop-types';
import renderHTML from 'react-render-html';

import * as S from './styled';

const List = ({
  dark, data, fluid, size,
}) => (
  <S.List dark={dark} fluid={fluid} size={size}>
    {data.map((item) => (
      <S.ListItem key={item.text} dark={dark}>
        <S.IconList size={size} dark={dark}>
          {item.icon}
        </S.IconList>
        <S.TextList>{renderHTML(item.text)}</S.TextList>
      </S.ListItem>
    ))}
  </S.List>
);

List.propTypes = {
  dark: PropTypes.bool,
  data: PropTypes.arrayOf().isRequired,
  fluid: PropTypes.bool,
  size: PropTypes.string,
};

List.defaultProps = {
  dark: false,
  fluid: false,
  size: 'small',
};

export default List;
