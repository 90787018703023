import styled from 'styled-components';

interface ButtonProps {
  fluid: boolean
}

export const Button = styled.button<ButtonProps>`
  background-color: #FE3E6D;
  border: none;
  color: #FFF;
  border-radius: 12px;
  font-size: 14px;
  height: 48px;
  padding: 0 50px;
  width: 100%;
  cursor: pointer;

  @media screen and (min-width: 768px) {
    width: ${({ fluid }) => (fluid ? '100%' : '270px')};
  }

  @media screen and (min-width: 1200px) {
    border-radius: 16px;
    font-size: 16px;
    height: 64px;
    width: ${({ fluid }) => (fluid ? '100%' : '270px')};
  }
`;
