import styled from 'styled-components';

export const SocialWrapper = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: 768px) {
    align-items: center;
    margin-left: auto;
  }
`;

export const SocialItem = styled.li`
  &:not(:last-child) {
    margin-right: 24px;
  }
`;

export const Link = styled.a`
  display: inline-block;
  height: 32px;
  width: 32px;
`;
