import iconBoleto from '../images/icon-boleto-74w.png';
import iconSendBoleto from '../images/icon-enviar-boletos-74w.png';
import iconPayment from '../images/icon-pagamentos-74w.png';

const paymentData = [
  {
    icon: iconBoleto,
    text: 'Você abre uma conta para sua empresa na Cora e começa a emitir suas cobranças via boletos.',
  },
  {
    icon: iconSendBoleto,
    text: 'Você envia os boletos para seus clientes que efetuam o pagamento das cobranças.',
  },
  {
    icon: iconPayment,
    text: 'Os pagamentos são creditados na Cora, e você pode pagar contas ou transferir o dinheiro  para outros bancos.',
  },
];

export default paymentData;
