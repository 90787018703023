import React, { FC } from 'react';

import * as S from './styled';

import ImageSmall from '../../../images/google-play-152w.png';
import ImageMedium from '../../../images/google-play-172w.png';
import ImageLarge from '../../../images/google-play-222w.png';

interface IconGooglePlayProps {
  className?: string
  link?: string
  small?: boolean
}

const IconGooglePlay: FC<IconGooglePlayProps> = ({
  className,
  link = 'https://play.google.com/store/apps/details?id=br.com.cora.bank&referrer=utm_source%3Dlanding_page%26utm_medium%3Dboletos',
  small = false
}: IconGooglePlayProps) => (
  <S.GooglePlay
    href={link}
    className={className}
    target="_blank"
    rel="noopener noreferrer"
  >
    <picture>
      {small ? (
        <>
          <source media="(max-width: 400px)" srcSet={ImageSmall} />
          <img src={ImageMedium} alt="imagem com boletos voando" />
        </>
      ) : (
        <>
          <source media="(max-width: 400px)" srcSet={ImageSmall} />
          <source media="(max-width: 1199px)" srcSet={ImageMedium} />
          <img src={ImageLarge} alt="imagem com boletos voando" />
        </>
      )}
    </picture>
  </S.GooglePlay>
);

export default IconGooglePlay;
